exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buscador-js": () => import("./../../../src/pages/buscador.js" /* webpackChunkName: "component---src-pages-buscador-js" */),
  "component---src-pages-certificate-js": () => import("./../../../src/pages/certificate.js" /* webpackChunkName: "component---src-pages-certificate-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-diplomados-js": () => import("./../../../src/pages/diplomados.js" /* webpackChunkName: "component---src-pages-diplomados-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-masterclasses-js": () => import("./../../../src/pages/masterclasses.js" /* webpackChunkName: "component---src-pages-masterclasses-js" */),
  "component---src-pages-otroscursos-js": () => import("./../../../src/pages/otroscursos.js" /* webpackChunkName: "component---src-pages-otroscursos-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-publicaciones-js": () => import("./../../../src/pages/publicaciones.js" /* webpackChunkName: "component---src-pages-publicaciones-js" */),
  "component---src-pages-roxana-js": () => import("./../../../src/pages/roxana.js" /* webpackChunkName: "component---src-pages-roxana-js" */)
}

